/************
 * Developed by : Shiva Software Solutions
 * Date : 23-06-2021
 * Descriptions : Call api response using reducers
 ************/
import {
  GET_HC_DATA,
  DASHBOARD_PENDING_DATA,
  DASHBOARD_PENDING_COUNT,
  DASHBOARD_PENDING_PATIENT_DATA,
  PATIENT_SUCCESS_MSG,
  ERX_SUCCESS_MSG,
  DASHBOARD_ERX_DATA,
  ERX_IMG_ARR,
  ERX_IMG_OCR_ARR,
  ERX_IMG_VACC_ARR,
  GET_DOCTOR_EDITDATA,
  DASHBOARD_INSERT_PRESCRIPTION,
  DASHBOARD_INPROGRESSS_DATA,
  DASHBOARD_INPROGRESSS_COUNT,
  DOCTOR_SUCCESS_MSG,
  REJECT_ERX_MSG,
  DOCTOR_UPDATE_MSG,
  MEDICINE_SUCCESS_MSG,
  DASHBOARD_RX_DETAILS,
  DASHBOARD_ERX_DOC_OBSV,
  DASHBOARD_ERX_LAB_OBSV,
  DASHBOARD_ERX_PATIENT_VITAL,
  DASHBOARD_ERX_DOCPROCEDURE,
  DASHBOARD_ERX_MEDICINE_DETAIL,
  DASHBOARD_GRX_MEDICINE_DETAIL,
  DASHBOARD_REJECT_DATA,
  DASHBOARD_REJECT_COUNT,
  REJECT_ERX_SUCCESS_MSG,
  DASHBOARD_COMPLETED_COUNT,
  UNIT_DATA,
  DASHBOARD_COMPLETED_DATA,
  DELETE_MEDCINE_SUCCESS_MSG,
  SET_DOCTOR_ID_NAME,
  BUCKET_CAPTCHA,
  DOCTOR_SPECIALIZATION,
  DOCTOR_SUBSPECIALIZATION,
  DOCTOR_QUALIFICATION,
  DEPARTMENT_MASTER,
  APPOINTMENT_COMPLETED_DATA,
  APPOINTMENT_UPCOMING_DATA,
  STATE_MASTER,
  OPT_NOTES,
  DOCTOR_SEARCH_ARRAY,
  DOCTOR_SEARCH_DATA,
  ERX_OPERATOR_NOTES,
  resulttitle,
  CHANGE_MOBILENO_RES,
  bloodsugarList,
  INVESTIGATION_CATEGORY_DATA,
  REPORT_DETAILS,
  SEARCH_REPORTS_DATA,
  TAG_CATEGORY_DATA,
  PATIENT_LISTS,
  SEARCH_PATIENT_DATA,
  SEARCH_PATIENT_OLDDATA,
  SEARCH_DISEASE_DATA,
  SEARCH_SYMPTOMS_DATA,
  ERXLIST_IMG_OCR_ARR,
  ERX_IMG_VACC_OCR_ARR,
  TODAY_APPOINTMENT_DATA,
} from '../../shared/constants/ActionTypes';

const initialState = {
  healthCare: null,
  dashboardPendingList: {},
  pendingCount: 0,
  dashboardPendingPatientData: {},
  patientsuccessmsg: null,
  erxsuccessmsg: null,
  rejecterxsuccessmsg: null,
  erxmedicinesuccessmsg: null,
  dashboarderxData: {},
  erximgdatas: [],
  appointmentcompleteddata: {},
  appointmentupcomingdata: {},
  erximgocrdataoriginal: [],
  erximgocrvaccoriginal: [],
  erxlistimgocrdataoriginal: [],
  dashboarderxDetails: {},
  dashboarderxDocobsv: {},
  dashboarderxLabobsv: {},
  today_appointment_data: {},
  dashboarderxPatientVital: {},
  dashboarderxDocProcedure: [],
  dashboarderxmedicinedetail: {},
  dashboardgrxmedicinedetail: {},
  doctorDetails: {},
  bucketcaptcha: {},
  dashboardinsertprescriptions: {},
  inprogresCount: 0,
  dashboardInprogressList: {},
  dashboardRejectList: {},
  dashboardRejectcountList: null,
  doctorsuccessmsg: null,
  doctorupdatemsg: null,
  rejectsuccessmsg: null,
  dashboardCompletedList: {},
  dashboardCompletedcountList: null,
  unitdata: [],
  deletemedicinesuccessmsg: null,
  doctoridname: {},
  specializations: [],
  subspecializations: [],
  qualifications: [],
  departmentMaster: [],
  stateMaster: [],
  optNotes: '',
  doctorSearchDatas: [],
  searchableDoctor: {},
  erxOperatorNotes: [],
  resulttitle: {},
  changemobilenores: '',
  bloodsugarList: {},
  investigationCategoryData: [],
  investigationDiseaseData: [],
  reportDetails: [],
  searchReportsData: [],
  tagCategoryData: [],
  tagDiseaseData: [],
  patientListdata: [],
  searchReportsData: [],
  search_patient_data: [],
  search_patient_olddata: [],
  search_disease_data: [],
  search_symptom_data: [],
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HC_DATA:
      return {
        ...state,
        healthCare: action.payload,
      };
    case OPT_NOTES:
      return {
        ...state,
        optNotes: action.payload,
      };

    //Assign dashboard pending data
    case DASHBOARD_PENDING_DATA:
      return {
        ...state,
        dashboardPendingList: action.payload,
      };
    //Assign dashboard pending count
    case DASHBOARD_PENDING_COUNT:
      return {
        ...state,
        pendingCount: action.payload,
      };
    //Assign dashboard pending data
    case DASHBOARD_REJECT_DATA:
      return {
        ...state,
        dashboardRejectList: action.payload,
      };
    //Assign dashboard pending count
    case DASHBOARD_REJECT_COUNT:
      return {
        ...state,
        dashboardRejectcountList: action.payload,
      };
    //Assign dashboard pending Patient data
    case DASHBOARD_PENDING_PATIENT_DATA:
      return {
        ...state,
        dashboardPendingPatientData: action.payload,
      };
    //Assign dashboard pending Patient success msg
    case PATIENT_SUCCESS_MSG:
      return {
        ...state,
        patientsuccessmsg: action.payload,
      };
    //Assign successmsg
    case ERX_SUCCESS_MSG:
      return {
        ...state,
        erxsuccessmsg: action.payload,
      };
    //Assign successmsg
    case REJECT_ERX_MSG:
      return {
        ...state,
        rejecterxsuccessmsg: action.payload,
      };

    //Assign successmsg
    case MEDICINE_SUCCESS_MSG:
      return {
        ...state,
        erxmedicinesuccessmsg: action.payload,
      };
    //Assign dashboard pending erx data
    case DASHBOARD_ERX_DATA:
      return {
        ...state,
        dashboarderxData: action.payload,
      };
    //get image data
    case ERX_IMG_ARR:
      return {
        ...state,
        erximgdatas: action.payload,
      };
    //get erx with img data
    case ERX_IMG_OCR_ARR:
      return {
        ...state,
        erximgocrdataoriginal: action.payload,
      };
    case ERX_IMG_VACC_ARR:
      return {
        ...state,
        erximgocrvaccoriginal: action.payload,
      };
    case ERXLIST_IMG_OCR_ARR:
      return {
        ...state,
        erxlistimgocrdataoriginal: action.payload,
      };
    case ERX_IMG_VACC_OCR_ARR:
      return {
        ...state,
        erxlistimgocrvaccoriginal: action.payload,
      };
    case DASHBOARD_RX_DETAILS:
      return {
        ...state,
        dashboarderxDetails: action.payload,
      };
    case DASHBOARD_ERX_DOC_OBSV:
      return {
        ...state,
        dashboarderxDocobsv: action.payload,
      };
    case DASHBOARD_ERX_LAB_OBSV:
      return {
        ...state,
        dashboarderxLabobsv: action.payload,
      };
    case DASHBOARD_ERX_PATIENT_VITAL:
      return {
        ...state,
        dashboarderxPatientVital: action.payload,
      };
    case DASHBOARD_ERX_DOCPROCEDURE:
      return {
        ...state,
        dashboarderxDocProcedure: action.payload,
      };

    case DASHBOARD_ERX_MEDICINE_DETAIL:
      return {
        ...state,
        dashboarderxmedicinedetail: action.payload,
      };
    //Get grx medicine details
    case DASHBOARD_GRX_MEDICINE_DETAIL:
      return {
        ...state,
        dashboardgrxmedicinedetail: action.payload,
      };
    //bind doctor edit load data
    case GET_DOCTOR_EDITDATA:
      return {
        ...state,
        doctorDetails: action.payload,
      };
    //Insert prescription
    case DASHBOARD_INSERT_PRESCRIPTION:
      return {
        ...state,
        dashboardinsertprescriptions: action.payload,
      };
    //Assign dashboard inprogress data
    case DASHBOARD_INPROGRESSS_DATA:
      return {
        ...state,
        dashboardInprogressList: action.payload,
      };
    //Assign inprogress count
    case DASHBOARD_INPROGRESSS_COUNT:
      return {
        ...state,
        inprogresCount: action.payload,
      };
    //doctor success msg
    case DOCTOR_SUCCESS_MSG:
      return {
        ...state,
        doctorsuccessmsg: action.payload,
      };

    //doctor update msg
    case DOCTOR_UPDATE_MSG:
      return {
        ...state,
        doctorupdatemsg: action.payload,
      };

    //REJECT_ERX_SUCCESS_MSG
    case REJECT_ERX_SUCCESS_MSG:
      return {
        ...state,
        rejectsuccessmsg: action.payload,
      };

    case DASHBOARD_COMPLETED_DATA:
      return {
        ...state,
        dashboardCompletedList: action.payload,
      };
    case DASHBOARD_COMPLETED_COUNT:
      return {
        ...state,
        dashboardCompletedcountList: action.payload,
      };
    case UNIT_DATA:
      return {
        ...state,
        unitdata: action.payload,
      };

    //Assign delete medicine details
    case DELETE_MEDCINE_SUCCESS_MSG:
      return {
        ...state,
        deletemedicinesuccessmsg: action.payload,
      };
    case SET_DOCTOR_ID_NAME:
      return {
        ...state,
        doctoridname: action.payload,
      };

    case BUCKET_CAPTCHA:
      return {
        ...state,
        bucketcaptcha: action.payload,
      };

    //for specialization master array
    case DOCTOR_SPECIALIZATION:
      return {
        ...state,
        specializations: action.payload,
      };
    case DOCTOR_SUBSPECIALIZATION:
      return {
        ...state,
        subspecializations: action.payload,
      };
    case DOCTOR_QUALIFICATION:
      return {
        ...state,
        qualifications: action.payload,
      };

    // master list
    case DEPARTMENT_MASTER:
      return {
        ...state,
        departmentMaster: action.payload,
      };
    case APPOINTMENT_COMPLETED_DATA:
      return {
        ...state,
        appointmentcompleteddata: action.payload,
      };
    case APPOINTMENT_UPCOMING_DATA:
      return {
        ...state,
        appointmentupcomingdata: action.payload,
      };
    case STATE_MASTER:
      return {
        ...state,
        stateMaster: action.payload,
      };
    case DOCTOR_SEARCH_ARRAY:
      return {
        ...state,
        doctorSearchDatas: action.payload,
      };

    case DOCTOR_SEARCH_DATA:
      return {
        ...state,
        searchableDoctor: action.payload,
      };
    //Get operator notes data
    case ERX_OPERATOR_NOTES:
      return {
        ...state,
        erxOperatorNotes: action.payload,
      };
    //Get title list
    case resulttitle:
      return {
        ...state,
        resulttitle: action.payload,
      };
    //Get blood sugar list
    case bloodsugarList:
      return {
        ...state,
        bloodsugarList: action.payload,
      };
    case CHANGE_MOBILENO_RES:
      return {
        ...state,
        changemobilenores: action.payload,
      };
    //Get Investigation category data
    case INVESTIGATION_CATEGORY_DATA:
      return {
        ...state,
        investigationCategoryData: action.payload,
      };
    case TAG_CATEGORY_DATA:
      return {
        ...state,
        tagCategoryData: action.payload,
      };
    case REPORT_DETAILS:
      return {
        ...state,
        reportDetails: action.payload,
      };
    case SEARCH_REPORTS_DATA:
      return {
        ...state,
        searchReportsData: action.payload,
      };
    case PATIENT_LISTS:
      return {
        ...state,
        patientListdata: action.payload,
      };
    case SEARCH_REPORTS_DATA:
      return {
        ...state,
        searchReportsData: action.payload,
      };
    case SEARCH_PATIENT_DATA:
      return {
        ...state,
        search_patient_data: action.payload,
      };
    case SEARCH_PATIENT_OLDDATA:
      return {
        ...state,
        search_patient_olddata: action.payload,
      };
    case SEARCH_DISEASE_DATA:
      return {
        ...state,
        search_disease_data: action.payload,
      };
    case TODAY_APPOINTMENT_DATA:
      return {
        ...state,
        today_appointment_data: action.payload,
      };
    case SEARCH_SYMPTOMS_DATA:
      return {
        ...state,
        search_symptom_data: action.payload,
      };

    default:
      return state;
  }
};
export default dashboardReducer;
